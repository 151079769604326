import React, { useState } from "react";
import { motion } from "framer-motion";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import Heading from "../../../components-v2/Base/HeadingBuilder";
import Image from "../../../components/ImageQuerys/RandomImages";
import GlobalImage from "../../../components/image";
import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
import gif1 from "../../../images/random/qr-step-1.gif";
import gif2 from "../../../images/random/qr-step-2.gif";
import gif3 from "../../../images/random/qr-step-3.gif";
import vector from "../../../images/icons/vector.png";
import {
  hero,
  howItWorks,
  steps,
  largeFeatures,
  quotesSlider,
  form,
  footer,
} from "../../../data/product-pages/qr";
import {
  heroQR,
  tonysPizzeriaSlides,
} from "../../../data/success-stories-data";
import heroBg from "../../../images/svg/vector-18.svg";
import heroBgLeft from "../../../images/svg/vector-16.svg";
import quotesBackground from "../../../images/svg/quotes-blue.svg";
import doubleCircleBig from "../../../images/svg/double-circle-top-right-big.svg";
import doubleCircleSmall from "../../../images/svg/double-circle-top-right-small.svg";
import quotes from "../../../images/svg/quotes.svg";
import AppetizeStyles from "../../../styles/lp-styles/appetize-lp-styles";
import QrForm from "../../../components/Forms/QrForm";
import FooterHero from "../../../components-v2/Sections/Hero/FooterHero";

import "../../../css/products-qr.css";

const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);

const quotesSection = [
  <motion.div
    key={quotesSlider[0].imageSubtitle}
    className="quotes__item"
    initial={{ opacity: 0, x: "-100px" }}
    animate={{ opacity: 1, x: 0 }}
  >
    <p className="quotes__item--subtext">{quotesSlider[0].subtext}</p>
    <GlobalImage
      className="quotes__item--image"
      imageName={quotesSlider[0].imageName}
    />
    <div className="quotes__item--title">{quotesSlider[0].imageTitle}</div>
    <div className="quotes__item--sub-title">
      {quotesSlider[0].imageSubtitle}
    </div>
  </motion.div>,
  <motion.div
    key={quotesSlider[1].imageSubtitle}
    className="quotes__item"
    initial={{ opacity: 0, x: "100px" }}
    animate={{ opacity: 1, x: 0 }}
  >
    <p className="quotes__item--subtext">{quotesSlider[1].subtext}</p>
    <GlobalImage
      className="quotes__item--image"
      imageName={quotesSlider[1].imageName}
    />
    <div className="quotes__item--title">{quotesSlider[1].imageTitle}</div>
    <div className="quotes__item--sub-title">
      {quotesSlider[1].imageSubtitle}
    </div>
  </motion.div>,
  <motion.div
    key={quotesSlider[2].imageSubtitle}
    className="quotes__item"
    initial={{ opacity: 0, x: "100px" }}
    animate={{ opacity: 1, x: 0 }}
  >
    <p className="quotes__item--subtext">{quotesSlider[2].subtext}</p>
    <GlobalImage
      className="quotes__item--image"
      imageName={quotesSlider[2].imageName}
    />
    <div className="quotes__item--title">{quotesSlider[2].imageTitle}</div>
    <div className="quotes__item--sub-title">
      {quotesSlider[2].imageSubtitle}
    </div>
  </motion.div>,
];

const Qr = () => {
  const [slideIndex, setSlideIndex] = useState(0);

  return (
    <Layout
      transparentHeader
      isLanding
      useNewLPHeader
      className="non-fixed-width qr-page"
    >
      <SEO
        title="QR Code Restaurant Best Practices | SpotOn QR"
        description="Learn how to maximize your QR codes to turn tables faster, manage more tables per server, and increase order size for restaurants."
      />

      <section className="pt-40 md:pt-24 bg-black w-full relative">
        <img
          alt="background"
          src={heroBgLeft}
          className="absolute left-0 top-24 opacity-0 lg:opacity-100"
        />
        <img
          alt="background"
          src={heroBg}
          className="absolute -right-48 md:right-0 bottom-6"
        />
        <div
          className={`grid grid-cols-1 md:grid-cols-2 relative
        px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto
        gap-x-10 2xl:gap-x-48 gap-y-16
        `}
        >
          <div className="flex flex-col justify-center w-full">
            <Heading
              className="text-white xl:min-w-[650px]"
              level={1}
              injectionType={1}
              withBlueSymbol
            >
              {hero.title}
            </Heading>

            <div className="text-black-500 text-p-large">
              {hero.descriptions.map((text, idx) => (
                <p
                  key={idx.toString()}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              ))}
            </div>
          </div>

          <div className="w-full flex flex-row justify-end">
            <Image
              className="w-full h-auto max-w-[420px]"
              imageName={hero.imageName}
            />
          </div>
        </div>

        {/* <div */}
        {/*  className="relative bg-black w-full py-7" */}
        {/*  style={{ borderTop: "1px solid #fff" }} */}
        {/* > */}
        {/*  {hero.footer.map((item, idx) => ( */}
        {/*    <span */}
        {/*      key={(idx + 1).toString()} */}
        {/*      className="text-white text-[14px] px-5 md:px-10 font-thin" */}
        {/*    > */}
        {/*      {item} */}
        {/*    </span> */}
        {/*  ))} */}
        {/* </div> */}
      </section>

      {/* <section
        className={`mt-28
        grid grid-cols-1 lg:grid-cols-2 gap-y-6 gap-x-10
        px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto
      `}
      > */}
      <section
        className={`mt-28
        flex flex-col xl:flex-row justify-between xl:items-center
        px-4 md:px-10 lg:px-20 xl:px-40 w-full max-w-1440 mx-auto
      `}
      >
        <div className="max-w-[370px]">
          <Heading
            level={2}
            injectionType={2}
            className="lg:max-w-[370px]"
            withBlueSymbol
            symbol="."
          >
            {howItWorks.title}
          </Heading>
        </div>

        <div className="flex flex-col md:flex-row gap-x-6 gap-y-2">
          <div
            className="md:border md:border-solid
              md:border-l-1 md:border-l-black-700
              md:border-r-1 md:border-r-black-700
              md:px-5 flex flex-row items-start"
            style={{ borderTop: 0, borderBottom: 0 }}
          >
            <div className="text-h1 text-black font-bold">6%</div>
            <img
              src={vector}
              alt="arrow"
              className="pl-[5px] scale-50 self-end md:mb-2"
            />
          </div>
          <p
            className="text-black-200 text-p max-w-[470px]"
            dangerouslySetInnerHTML={{ __html: howItWorks.description }}
          />
        </div>
      </section>

      <section
        className={`mt-10 lg:mt-16 w-full
        flex flex-col md:flex-row justify-between items-start
        gap-y-12 gap-x-4 lg:gap-x-8
        px-4 md:px-10 lg:px-20 xl:px-40 max-w-1440 mx-auto
        
      `}
      >
        {/* Image 1 */}
        <div
          className={` 
          flex flex-col items-center justify-center
          w-full h-auto
          row-span-1
        `}
        >
          {/* <Image className="w-full" imageName="qr-step-1.gif" /> */}
          <img
            src={gif1}
            alt="spoton product"
            className="w-full rounded-[24px]"
            loading="lazy"
          />

          <div className="mt-6 max-w-[310px]">
            <div className="black-100 text-[24px] font-bold">
              {steps[0].title}
            </div>
            <p className="black-200 text-p mt-4">{steps[0].description}</p>
            <ul className="black-200 text-p mt-6 pl-[18px]">
              {steps[0].bullets.map((bullet) => (
                <li key={bullet} className="">
                  {bullet}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Image 2 */}
        <div
          className={` 
          flex flex-col items-center justify-center
          w-full h-auto
          row-span-1
        `}
        >
          <img
            src={gif2}
            alt="spoton product"
            className="w-full rounded-[24px]"
            loading="lazy"
          />

          <div className="mt-6 max-w-[310px]">
            <div className="black-100 text-[24px] font-bold">
              {steps[1].title}
            </div>
            <p className="black-200 text-p mt-4">{steps[1].description}</p>
            <ul className="black-200 text-p mt-6 pl-[18px]">
              {steps[1].bullets.map((bullet) => (
                <li key={bullet} className="">
                  {bullet}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Image 3 */}
        <div
          className={` 
          flex flex-col items-center justify-center
          w-full h-auto
          row-span-1
        `}
        >
          <img
            src={gif3}
            alt="spoton product"
            className="w-full rounded-[24px]"
            loading="lazy"
          />

          <div className="mt-6 max-w-[310px]">
            <div className="black-100 text-[24px] font-bold">
              {steps[2].title}
            </div>
            <p className="black-200 text-p mt-4">{steps[2].description}</p>
            <ul className="black-200 text-p mt-6 pl-[18px]">
              {steps[2].bullets.map((bullet) => (
                <li key={bullet} className="">
                  {bullet}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="max-w-[1360px] mt-12 lg:mr-">
        <SuccessStories sectionData={heroQR} slides={tonysPizzeriaSlides} />
      </section>

      <LargeFeatures className="mt-24 lg:mt-48" sectionData={largeFeatures} />

      <section className="max-w-1440 w-full lg:px-10">
        <div className="quotes">
          <img
            className="quotes__quotes-bg"
            src={quotesBackground}
            alt="quotes background"
          />

          <img
            className="quotes__circle-bg-big"
            src={doubleCircleBig}
            alt="circles"
          />
          <img
            className="quotes__circle-bg-small"
            src={doubleCircleSmall}
            alt="circles"
          />

          <img className="quotes__transparent" src={quotes} alt="quotes" />
          <div className="quotes__items-wrapper">
            {quotesSection[slideIndex]}
          </div>

          <div className="quotes__buttons justify-center">
            <button
              type="button"
              className={`quotes__slide-button ${
                slideIndex === 0 ? "active" : ""
              }`}
              onClick={() => setSlideIndex(0)}
            >
              <div className="outer">
                <div className="inner" />
              </div>
            </button>

            <button
              type="button"
              className={`quotes__slide-button ${
                slideIndex === 1 ? "active" : ""
              }`}
              onClick={() => setSlideIndex(1)}
            >
              <div className="outer">
                <div className="inner" />
              </div>
            </button>

            <button
              type="button"
              className={`quotes__slide-button ${
                slideIndex === 2 ? "active" : ""
              }`}
              onClick={() => setSlideIndex(2)}
            >
              <div className="outer">
                <div className="inner" />
              </div>
            </button>
          </div>
        </div>
      </section>

      {/* <section className="relative w-full mx-auto mt-10 lg:mt-36 text-center px-4">
        <Heading withBlueSymbol className="text-h2" level={2} injectionType={2}>
          {form.title}
        </Heading>

        <p className="text-p text-black-300 max-w-[617px] mx-auto px-4">
          {form.description}
        </p>
        <QrForm />
      </section> */}

      <FooterHero
        className="mt-20 lg:mt-36 mb-12 lg:mb-20"
        {...footer}
        variant={2}
        titleBlueSymbol="?"
      />

      <AppetizeStyles />
    </Layout>
  );
};

export default Qr;
