export const hero = {
  title: "Boost Revenue and serve more guests with SpotOn QR",
  descriptions: [
    `Restaurants using SpotOn QR ordering saw check averages <span class="text-white">increase by 6.3%.</span>`,
    "Learn how to give guests a faster, easier ordering experience with SpotOn QR.",
  ],
  imageName: "qr-hero.png",
};

export const howItWorks = {
  title: `A better way to run your business`,
  description: `After using SpotOn QR for 3 months, restaurants served <span class="text-primary">6% more</span> guests on average. Choose the guest experience that’s right for your business.`,
};

export const steps = [
  {
    title: "Scan & pay",
    description: "Serve more guests by enabling diners to pay in <10 seconds.",
    bullets: [
      "Choose default tips",
      "Accept Apple & Google pay",
      "Drive repeat visits by letting guests easily earn & redeem loyalty rewards",
    ],
  },
  {
    title: "Dine-in order & pay",
    description: "Serve more guests each shift",
    bullets: [
      "Spend more time building relationships with your guests",
      "Give guests the ability to join an order and split the bill by amount",
    ],
  },
  {
    title: "Counter service order & pay",
    description: "Manage lines & keep orders flowing",
    bullets: [
      "Accept orders from anywhere",
      "Issue call numbers so people know their spot in line",
      "Manage guest expectations with 2-way texting",
    ],
  },
];

export const largeFeatures = {
  sectionTitle:
    "QR that works the way you work, saving you time and improving operations",
  featureBlocks: [
    {
      blockTitle: "Boost efficiency with seamless POS integration",
      blockImg: "qr-success-1.png",
      blockSubTitle: "",
      forceReverse: false,
      blockList: [
        `Your digital menu automatically updates based on item availability`,
        `Set pacing for takeout orders versus POS and dine-in QR orders`,
        `All orders appear in the POS, streamlining BOH and giving you better data insights`,
        `Increase tips by pre-setting default tip amounts`,
        `No additional hardware or app needed`,
      ],
      // ctaInfo: {
      //   ctaTitle:
      //     "Read more on how to successfully roll out QR ordering here. ",
      //   ctaTarget:
      //     "https://spoton.com/blog/restaurant-qr-ordering-tips-for-success/",
      // },
      footer: ``,
    },
    {
      blockTitle: "Give guests a great experience",
      blockImg: "qr-success-2.png",
      blockSubTitle: "",
      blockList: [
        `Make it easy for guests to view the menu from the moment they sit down by placing a printed QR with simple instructions on the table`,
        `Customize the QR ordering experience to match your restaurant’s branding`,
        `Enable guests to join an order and split the bill or order individually`,
        `Choose to allow guests to add to their order and pay at the end, or pay each time`,
      ],
      forceReverse: false,
    },
    {
      blockTitle: "Reward each visit & keep guests coming back",
      blockImg: "qr-success-3.png",
      blockSubTitle: "",
      blockList: [
        `With SpotOn loyalty, guests can earn spots with each visit, helping you drive repeat visits`,
        `Easy reward redemption through QR orders by your choice of dollar amount or percentage`,
        `Collect guest information to market to them through email`,
      ],
      forceReverse: false,
    },
    {
      blockTitle: "Tips and tricks to drive more revenue using QR",
      blockImg: "qr-success-4.png",
      blockSubTitle: "",
      blockList: [
        `Set up your <a href="https://help.spoton.com/space/SK/2087780512#How-do-I-set-up-Tip-Percentage-Defaults?" target="_blank">default tips amounts to help servers maximize tips</а>`,
        `<a href="https://help.spoton.com/space/SK/1976205360/Custom%20Theming%20%26%20Image%20Sizing%20%7C%20SpotOn%20Order#Custom-Theming-&-Image-Sizing" target="_blank">Custom branding to match your look and feel</а>`,
        `Set rules so guests must <a href="https://help.spoton.com/space/SK/2087780512#Customer-QR-Group-Order-Flow" target="_blank">pay after each request</a> or can continue to <a href="https://help.spoton.com/space/SK/2087780512#Pay-Later-Enabled" target="_blank">add items and pay later</a>`,
        `<a href="https://spotonteam.atlassian.net/wiki/spaces/BD/pages/2452685021/Sprint+48+Release+Notes" target="_blank">Have QR associated with individual tables for full service, or choose to have call numbers for each order</a>`,
        `Sync <a href="https://help.spoton.com/space/SK/2087780512#Checking-Out-&-Loyalty" target="_blank">QR order with your digital loyalty program</a>`,
      ],
      forceReverse: false,
    },
  ],
};

export const quotesSlider = [
  {
    subtext: ` “Customers can come into the restaurant, scan a QR code, and order and pay for their food using their phones. Everything can be done online while they're in the restaurant. That's a huge help to my staff. That's an extra minute a server can spend at another table explaining the menu or cleaning a table.”`,
    imageName: "tonys-logo.png",
    imageTitle: "Chuck Conrose",
    imageSubtitle: "Owner, Tony’s Pizza, Fort Myers, FL",
  },
  {
    subtext: `“We think the future is in QR codes in a lot of ways, where we're going to be able to offer our guests a faster, better experience with less labor.”`,
    imageName: "loma-logo.png",
    imageTitle: "Kevin Youkilis",
    imageSubtitle: "Owner, Loma Brewing Company, Los Gatos, CA",
  },
  {
    subtext: `“SpotOn’s QR Code technology allows guests to get a second or third beer without a long wait time or to get up to go somewhere to get it. We were typically seeing between $30 – $32 per guest on average, and now we’re seeing $40 – $50 per guest on average. We owe that to those additional beverages hitting those guest checks.”`,
    imageName: "eldors-logo.png",
    imageTitle: "Hillary Holmes",
    imageSubtitle:
      "General Manager, Von Elrod’s Beer Hall & Kitchen, Nashville, TN",
  },
];

export const form = {
  title: "Get your personalized QR codes",
  description:
    "Let us help you! As a SpotOn client, you can get your personalized restaurant QR codes printed and laminated, with or without a stand, sent to you. ",
};

export const footer = {
  title: "Not yet using SpotOn QR",
  subtext:
    "Talk to one of our restaurant technology pros and see how easy it is to set up QR order and pay and start boosting your bottom line.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/products/qr/demo",
  },
};
