import React from "react";
import { string, shape, bool, oneOf } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../../Base/HeadingBuilder";
import CtaPrimary from "../../../Base/CtaPrimary";
import backgroundBigV1 from "../../../../images/svg/half-circle-big.svg";
import backgroundSmallV1 from "../../../../images/svg/half-circle-big-filled.svg";
import backgroundBigV2 from "../../../../images/svg/vector-03.svg";
import backgroundSmallV2 from "../../../../images/svg/vector-04.svg";
import useStartMotion from "../../../../hooks/use-start-motion";
import { viewports } from "../../../../style-vars";

const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const leftBackgroundVariants = {
  hidden: { opacity: 0, x: -350, y: 350 },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
  },
};

const rightBackgroundVariants = {
  hidden: { opacity: 0, x: 350, y: -350 },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
  },
};

const bottomRightVariants = {
  hidden: { opacity: 0, x: 350, y: 350 },
  visible: {
    opacity: 1,
    x: 0,
    y: 0,
  },
};

const FooterHero = ({
  className,
  title,
  titleBlueSymbol,
  subtext,
  label,
  ctaPrimary,
  animations,
  variant,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      data-cy="footer-hero"
      className={`relative overflow-hidden
      max-w-1440 w-full
      mx-0 lg:mx-10
      px-4 md:px-10 xl:px-40
      py-64 xl:py-80
      bg-black rounded-large
      grid grid-cols-1
      lg:grid-cols-2 lg:gap-20
      xl:gap-60
      ${className}
    `}
      ref={ref}
    >
      {variant === 1 && (
        <motion.img
          className={`
          absolute
          right-0 -bottom-80
          lg:-bottom-64
          xl:-bottom-40
        `}
          src={backgroundBigV1}
          alt="background"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={bottomRightVariants}
          transition={{ delay: 0.3, duration: 0.8 }}
        />
      )}
      {variant === 1 && (
        <motion.img
          className={`
          absolute
          -left-56 -top-48
          lg:left-0
          xl:left-24 xl:-top-32
        `}
          src={backgroundSmallV1}
          alt="background"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={rightBackgroundVariants}
          transition={{ duration: 0.8 }}
        />
      )}

      {variant === 2 && (
        <motion.img
          className={`
          absolute
          -left-96 -bottom-96
          lg:-left-40 lg:-bottom-40
          xl:left-0 xl:bottom-0
        `}
          src={backgroundBigV2}
          alt="background"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={leftBackgroundVariants}
          transition={{ delay: 0.3, duration: 0.8 }}
        />
      )}
      {variant === 2 && (
        <motion.img
          className={`
          absolute
          right-0
          top-0
        `}
          src={backgroundSmallV2}
          alt="background"
          animate={controls}
          initial={isMobileOnly || !animations ? "visible" : "hidden"}
          variants={rightBackgroundVariants}
          transition={{ duration: 0.8 }}
        />
      )}

      <motion.div
        className="relative"
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.6, duration: 0.8 }}
      >
        <Heading
          level={2}
          injectionType={1}
          className="text-white"
          withBlueSymbol
          symbol={titleBlueSymbol}
        >
          {title}
        </Heading>
      </motion.div>

      <motion.div
        className="relative"
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.1, duration: 0.8 }}
      >
        <p className="text-black-700 text-p">{subtext}</p>

        <CtaPrimary
          className="mt-10"
          title={ctaPrimary.title}
          target={ctaPrimary.target}
        />
      </motion.div>

      <div
        className={`
          absolute text-white text-xs
          -left-4 bottom-20
          md:left-2
          xl:left-32
          transform -rotate-90
        `}
      >
        {label}
      </div>
    </section>
  );
};

FooterHero.propTypes = {
  className: string,
  title: string.isRequired,
  titleBlueSymbol: string,
  subtext: string.isRequired,
  label: string,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  animations: bool,
  variant: oneOf([1, 2]),
};

FooterHero.defaultProps = {
  className: "",
  label: "",
  titleBlueSymbol: ".",
  ctaPrimary: null,
  animations: true,
  variant: 1,
};

export default FooterHero;
