import React, { useState } from "react";
import { Form, Input, Radio, Button, Select } from "antd";
import { navigate } from "gatsby";
import chevron from "../../images/svg/chevron_left.svg";
import background from "../../images/svg/vector-17.svg";
import { encode } from "./EnterpriseForm";
import { emailValidationRegExp } from "../../constants";

const { Option } = Select;
const netlifyFormName = "QR Form";

const QrForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);

    await fetch(`/`, {
      method: `POST`,
      body: encode({
        "form-name": netlifyFormName,
        client_type: values.client_type,
        restaurant_name: values.restaurant_name,
        tables_set_for_qr: values.tables_set_for_qr,
        tables: values.tables,
        person_submitting_name: values.person_submitting_name,
        person_submitting_email: values.person_submitting_email,
      }),
    })
      .then(
        () => {
          setLoading(false);
          navigate("/products/qr/thank-you");
        },
        (err) => {
          setLoading(false);
        }
      )
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      {/* Netlify form */}
      <form name={netlifyFormName} data-netlify="true" hidden>
        <input type="text" name="client_type" />
        <input type="text" name="restaurant_name" />
        <input type="text" name="tables_set_for_qr" />
        <input type="text" name="tables" />
        <input type="text" name="person_submitting_name" />
        <input type="text" name="person_submitting_email" />
      </form>

      <div className="relative pb-20 flex justify-center w-full">
        <img
          src={background}
          alt="background"
          className="absolute left-[50%] top-10 md:top-0 lg:top-4"
          style={{ transform: "translateX(-50%) scale(1)" }}
        />

        <div
          className={`relative max-w-[852px]
          mt-12 lg:mt-14 mx-4
          p-6 lg:px-16 lg:py-10 
          bg-white border-solid rounded-lg border-black-700
        `}
          style={{ boxShadow: "0px 32px 60px rgba(0, 0, 0, 0.07)" }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            className="text-left"
          >
            <Form.Item
              label="Are you a current or new SpotOn client?"
              name="client_type"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                  min: 2,
                },
              ]}
            >
              <Select aria-required placeholder="Please select" size="large">
                <Option value="existing_client">
                  I am a current SpotOn client
                </Option>
                <Option value="new_client">
                  I am interested in getting QR Order & Pay
                </Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="What is your restaurant's name?"
              name="restaurant_name"
              rules={[
                {
                  required: true,
                  message: "Please enter the restaurant name",
                  min: 2,
                },
              ]}
            >
              <Input
                aria-required
                type="text"
                placeholder="Eg. Test Restaurant"
                size="large"
              />
            </Form.Item>

            <Form.Item
              name="tables_set_for_qr"
              label="Are your tables setup on SpotOn for QR?"
              rules={[
                {
                  required: true,
                  message: "Please select an option",
                },
              ]}
            >
              <Radio.Group>
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Radio.Group>
            </Form.Item>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
              <Form.Item
                className="flex"
                style={{ flexDirection: "row" }}
                label="How many tables do you have?"
                name="tables"
                rules={[
                  {
                    required: true,
                    message: "Please enter the amount of tables",
                    min: 1,
                  },
                ]}
              >
                <Input
                  aria-required
                  type="number"
                  placeholder="Eg. 24"
                  size="large"
                />
              </Form.Item>

              <Form.Item
                className="flex"
                style={{ flexDirection: "row" }}
                label="Name of person submitting this request"
                name="person_submitting_name"
                rules={[
                  {
                    required: true,
                    message: "Please enter your name",
                    min: 1,
                  },
                ]}
              >
                <Input
                  aria-required
                  type="text"
                  placeholder="Eg. John Doe"
                  size="large"
                />
              </Form.Item>
            </div>

            <Form.Item
              label="Your email"
              name="person_submitting_email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                  min: 1,
                  pattern: emailValidationRegExp,
                },
              ]}
            >
              <Input
                aria-required
                type="email"
                placeholder="Eg. john.doe@gmail.com"
                size="large"
              />
            </Form.Item>

            {/* Submit */}
            <div className="flex justify-center">
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="cta-primary"
                  size="small"
                  disabled={loading}
                >
                  Submit
                  <img
                    src={chevron}
                    alt="chevron icon"
                    style={{ margin: "5px 5px 2px 10px" }}
                  />
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default QrForm;
